export const addOptions = (newTodo) => {

    console.log("fsdssdsds", newTodo)

    // console.log("fsdssdsds", data)
    return {
        optimisticData: (data) => [...data.data, newTodo],

        // optimisticData: (data) => [...data.data, newTodo],
       // rollbackOnError: true,
        //populateCache: (added, data) => [...data, added],
        populateCache: (added, data) => [...data.data, added],
       // revalidate: false
    }
}

// export const updateDataOptions = (upData) => {
//     return {
//         optimisticData: (todos) => {
//             console.log("ooooooooooooooo",todos)
//             const prevTodos = todos.data.filter(todo => {
//                 return todo.id !== upData.id
//             })
//             return [...prevTodos, upData]
//                 .sort((a, b) => b.id - a.id)
//         },
//        // rollbackOnError: true,
//         populateCache: (updated, todos) => {
//             console.log("ooooooooooooooo2",updated)
//             const prevTodos = todos.data.filter(todo => {
//                 return todo.id !== upData.id
//             })
//             return [...prevTodos, updated]
//                 .sort((a, b) => b.id - a.id)
//         },
//       //  revalidate: false,
//     }
// }

export const deleteTodoOptions = ( id ) => {
  
    return {
        optimisticData: (data) => {
            return data.data.filter(data => {
                return data.id !== id
            })
        },
      //  rollbackOnError: true,

        populateCache: (emptyResponseObj, data) => {
            return data.data.filter(data => {
                return data.id !== id
            })
        },
      //  revalidate: false,
    }
}