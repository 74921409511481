import { forwardRef } from "react";

const UBKSubmit = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  // console.log("ssasas",""+rest.label),

  <div className="form-group">

    <input className="btn btn-primary btn-sm" {...rest} ref={ref} />

  </div>
));



export default UBKSubmit;
