import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import UBKfile from "../../components/UBKfile";
// import Visitoredit from "layouts/EditPage/Visitoredit";
import { ConfigFile } from "../../services/ConfigFile";
import UBKSubmit from "../../components/UBKSubmit";
import toast, { Toaster } from "react-hot-toast";
import useSWR, { mutate as globalMutate } from "swr";
import { Table, Image } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import { Drawer } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import {
    getNewData,
    addData,
    deleteData,
    updateData,
    getadmindataUrl,
    getAdminbranchDataUrl as cachekey
} from "../../Api/api";

import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
// import EditBranch from "./EditBranch ";
import UBKCheckbox from "../../components/UBKCheckbox";
import { useRef } from "react";
// import SelectRequired from "../../components/UBKSelect/SelectRequired";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import DonutCard from "../../components/DonutCard";
import PieCard from "../../components/PieCard/PieCard";



// ==========MAIN FUNCTION ======================

const data = {
    // labels: [
    //     'Red',
    //     'Blue',
    //     'Yellow'
    // ],
    datasets: [{
        // label: 'My First Dataset',
        data: [80250, 20200],
        backgroundColor: [
            '#1A73E8',
            '#e91e63'
        ],
        hoverOffset: 4,
        borderRadius: 4,
        spacing: 0.5
    }]
};
const obj = [
    {
        label: "Income"
    },
    {
        label: "Expense"
    }
]
const data3 = {
    labels: [
        'Red',
        'Blue',
        'Yellow'
    ],
    datasets: [{
        // label: 'My First Dataset',
        data: [90, 10],
        backgroundColor: [
            '#1e1efa',
            '#cccccc'
        ],
        hoverOffset: 4,
        borderRadius: 4,
        spacing: 0.5
    }]
};

const obj3 = [
    {
        label: "Present"
    },
    {
        label: "Absent"
    }
]

const data4 = {
    labels: [
        'Rent Bill',
        'Other Maintenance',
        'Cleaning Maintenance',
        'Electric Bill',
        'Internet Bill'
    ],
    datasets: [{
        // label: 'My First Dataset',

        data: [4000, 2100, 1100, 1600, 500],
        backgroundColor: [
            '#244201',
            '#3b6e00',
            '#36c7bb',
            '#66aa18',
            '#88ff00'
        ],
        hoverOffset: 4,
        borderRadius: 4,
        spacing: 0.5
    }]
};
const obj4 = [
    {
        label: "Rent Bill",
        fontSize: "8.8px"
    },
    {
        label: "Other Maintenance",
        fontSize: "8.8px"
    },
    {
        label: "Cleaning Maintenance",
        fontSize: "8.8px"
    },
    {
        label: "Electric Bill",
        fontSize: "8.8px"
    },
    {
        label: "Internet Bill",
        fontSize: "8.8px"
    },
]
const data5 = {
    labels: [
        'Cash',
        'Bank Deposite',
        'UIP and Check',
        'Online App',
    ],
    datasets: [{
        // label: 'My First Dataset',

        data: [3700, 3200, 1700, 1500],
        backgroundColor: [
            '#8704e4',
            '#ff7f50',
            '#36c7bb',
            '#88ff00'
        ],
        hoverOffset: 4,
        borderRadius: 8,
        spacing: 1
    }]
};
const obj5 = [
    {
        label: "Rent Bill",

    },
    {
        label: "Other Maintenance",

    },
    {
        label: "Cleaning Maintenance",

    },
    {
        label: "Electric Bill",

    },
    {
        label: "Internet Bill",

    },
]

const data1 = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
}




function SingleBranchReport() {


    // const pluginForDoughnut = {
    //     id: 'MyPlugin1',
    //     beforeDraw: (chart, args, options) =>{
    //         const {ctx,data,getDatasetMeta} = chart;
    //         console.log(getDatasetMeta);
    //         ctx.save();
    //         // ctx.fillRect(0, 0, chart.width, chart.height);
    //         // ctx.restore();
    //         ctx.font="bold 50px sans-serif";
    //         ctx.fillStyle='red';
    //         ctx.textAlign="center";
    //         ctx.fillText("mytext1","mytext2","mytext3");
    //     }
    // }



    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
        <EditBranch data={{ id, handleUpdate }} />
      </Drawer> */}
            <Toaster toastOptions={{ position: "top-center" }} />
            <MDBox pt={8} pb={1}>
                <Grid container spacing={0.6}>
                    <Grid item xs={12} md={4}>
                        <div style={{ height: "170px" }}>
                            <DonutCard
                                data={
                                    {
                                        data: data,
                                        labelArr: obj,
                                        headings: "Income Expense Report",
                                        myIcon: <IconButton style={{ backgroundColor: "#e6e6e6", borderRadius: "50%" }}>
                                            <SsidChartIcon color="black" fontSize="medium" />
                                        </IconButton>
                                    }
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{ height: "170px" }}>
                            <DonutCard
                                data={
                                    {
                                        data: data3,
                                        labelArr: obj3,
                                        notShowData: true,
                                        headings: "Student Attendance Report",
                                        isPercent: { fontWeight: "bold" },
                                        myIcon: <IconButton style={{ backgroundColor: "#e6e6e6", borderRadius: "50%" }}>
                                            <SsidChartIcon color="black" fontSize="medium" />
                                        </IconButton>
                                    }
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{ height: "170px" }}>
                            <DonutCard
                                data={
                                    {
                                        data: data4,
                                        labelArr: obj4,
                                        headings: "Cleaning and Other Maintenance",
                                        isPercent: true,
                                        myIcon: <IconButton style={{ backgroundColor: "#e6e6e6", borderRadius: "50%" }}>
                                            <SsidChartIcon color="black" fontSize="medium" />
                                        </IconButton>
                                    }
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <div style={{ height: "400px" }}></div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {/* <Card>
                            <div style={{ height: "300px" }}></div>
                        </Card> */}
                        <div style={{ height: "400px" }}>
                            <PieCard
                                data={
                                    {
                                        data: data5,
                                        labelArr: data5.labels,
                                        headings: "Total Payment Recieve",
                                        isPercent: { fontWeight: "bold" },
                                        myIcon: <IconButton style={{ backgroundColor: "#e6e6e6", borderRadius: "50%" }}>
                                            <SsidChartIcon color="black" fontSize="medium" />
                                        </IconButton>
                                    }
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <div style={{ height: "350px" }}></div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <div style={{ height: "350px" }}></div>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <div style={{ height: "180px" }}></div>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default SingleBranchReport;
