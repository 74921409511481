import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import UBKfile from "../../components/UBKfile";
// import Visitoredit from "layouts/EditPage/Visitoredit";
import { ConfigFile } from "../../services/ConfigFile";
import UBKSubmit from "../../components/UBKSubmit";
import toast, { Toaster } from "react-hot-toast";
import useSWR, { mutate as globalMutate } from "swr";
import { Table, Image } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import { Drawer } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
// import SelectRequired from "../../components/UBKSelect/SelectRequired";

import {
  getNewData,
  addData,
  deleteData,
  updateData,
  getadmindataUrl,
  getAdminbranchDataUrl as cachekey
} from "../../Api/api";

import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
// import EditBranch from "./EditBranch ";
import UBKCheckbox from "../../components/UBKCheckbox";
import { useNavigate } from "react-router-dom";

const EditableCell = ({
  dataIndex,
  title,
  record,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      <div style={{display:"flex",marginLeft:"10px"}}>{children}</div>
    </td>
  );
};

// ==========MAIN FUNCTION ======================



function BranchReport() {
  const path = ConfigFile.BaseUrl;
  const navigate=useNavigate();
  //   const [classnme, setclassnme] = useState("");
  //   const [open, setOpen] = useState(false);
  const [img, setImage] = useState();
  //   const [id, selectedId] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const addMutation = async (url, newData) => {
    try {
      await mutate(addData(url, newData), addOptions(newData));

      toast.success("Success! Added new item.", {
        duration: 1000,
        icon: "🎉",
      });
    } catch (err) {
      toast.error("Failed to add the new item.", {
        duration: 1000,
      });
    }
  };

  const {
    isLoading: menuLoading,
    error: menuError,
    data: permissionMenu
  } = useSWR("/admin/Admin/getadminMenu", getNewData);
  const {
    isLoading,
    error,
    data: newData,
    mutate,
  } = useSWR(cachekey, getNewData);

  const {
    isLoading: loadingServices,
    error: serviceError,
    data: serviceList,
  } = useSWR("/admin/Admin/servicelist", getNewData);

  // const {
  //   isLoading:isLoadingAdminData,
  //   error,
  //   data: newdata,
  //   mutate,
  // } = useSWR("/api/superAdmin/admintable" + `?page=${controller.page + 1}&per_page=10`, getNewData);


  //   const deleteTodoMutation = async (id) => {
  //     const url = "/branchapi/frontoffice/deletevisitors";
  //     try {
  //       await mutate(deleteData(url, id), deleteTodoOptions(id));
  //       toast.success("Success! Deleted item.", {
  //         duration: 1000,
  //       });
  //     } catch (err) {
  //       toast.error("Failed to delete the item.", {
  //         duration: 1000,
  //       });
  //     }
  //   };

  //   const updateTodoMutation = async (url, upData) => {
  //     try {
  //       const res = await mutate(updateData(url, upData));
  //       console.log(res);
  //       if (Array.isArray(res?.data)) {
  //         globalMutate(`${getadmindataUrl}?id=${id}`, res);
  //       }
  //       toast.success("Success! Updated item.", {
  //         duration: 1000,
  //         icon: "🚀",
  //       });
  //     } catch (err) {
  //       toast.error("Failed to update the item.", {
  //         duration: 1000,
  //       });
  //     }
  //   };

  //   const handleUpdate = async (e) => {
  //     e.preventDefault();
  //     const formData = new FormData(e.target);
  //     const url = "/superAdmin/editadmin";
  //     updateTodoMutation(url, formData);
  //     e.target.reset();
  //     DrawerUpdate(false);
  //   };

  const handleSubmit = (event) => {
    console.log("2222222222", "handleSubmit");
    event.preventDefault();
    const formData = new FormData(event.target);
    addMutation("/admin/Admin/add_branch", formData);
    event.target.reset();
  };

  //   const DrawerUpdate = (status) => {
  //     setOpen(status);
  //   };
  const onChange = (page) => {
    console.log(page);
    setTableParams((prev) => (
      {
        ...prev,
        pagination: {
          current: 1,
          pageSize: prev.pagination.pageSize,
        }
      }))
  };

  console.log("2222222222", "okkk");

  // const columns = useMemo(() => [
  //   {
  //     id: "1",
  //     name: "First Name",
  //     selector: "first_name",
  //     sortable: true,
  //   },
  //   {
  //     id: "2",
  //     name: "Last Name",
  //     selector: "last_name",
  //     sortable: true,
  //   },
  //   {
  //     id: "3",
  //     name: "Email",
  //     selector: "email_id",
  //     sortable: true,
  //   }
  // ]);

  const columns = [
    {
      title: 'Branch Name',
      dataIndex: 'branch_name',
    },
    {
      title: 'No of Student',
      dataIndex: 'total_student',
    },
    {
      title: 'Total Staff',
      dataIndex: 'total_staff',
    },
    {
      title: 'Total Income',
      dataIndex: 'total_income',
    },
    {
      title: 'Total Expense',
      dataIndex: 'total_expense',
    },
    {
      title: 'Details',
      // dataIndex: 'id',
      // width: '30%',
      // key: 'email_id',
      render: (item, record, index) => <div onClick={() => editClick(record.id)} style={{ fontSize: "11px" }}>
        {/* {!record?.menu_name ? */}
        <IconButton>
          <InfoIcon sx={{ color: "#3892ee" }} fontSize="small" />
        </IconButton>
        {/* : null */}
        {/* } */}
      </div>,
    },

  ];
  const editClick = (id) => {
    // selectedId(id);
    // setOpen(status);
    // DrawerUpdate(true);
    navigate("/SingleBranchReport");
  };

  // const handleTableChange = (pagination, filters, sorter) => {
  //   setTableParams({
  //     pagination,
  //     filters,
  //     ...sorter,
  //   });
  // }

  const getCallbackSingle = (action) => (event) => {
    if (event == null || event == "undefined" || event == "") {
    } else {
      if (event[0].status === "done") {
        var name = event[0].response.data;
        setImage(name);
      } else {
      }
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
        <EditBranch data={{ id, handleUpdate }} />
      </Drawer> */}
      <Toaster toastOptions={{ position: "top-center" }} />
      <MDBox pt={8} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Branch List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {!isLoading && */}
                <>
                  <Table
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    style={{ margin: "50px" }}
                    columns={columns}
                    bordered={true}
                    rowKey={(record) => record.branch_id}
                    dataSource={newData?.data}
                    loading={isLoading}
                  // pagination={{
                  //   current: tableParams.pagination.current,
                  //   pageSize: tableParams.pagination.pageSize,
                  //   total: newData?.pagination?.total_rows,
                  //   showSizeChanger: true,
                  //   onChange: onChange,
                  //   onShowSizeChange: (current, pageSize) => {
                  //     setTableParams((prev) => (
                  //       {
                  //         ...prev,
                  //         pagination: {
                  //           current: 1,
                  //           pageSize: pageSize,
                  //         }
                  //       }))
                  //   },
                  //   showTotal: (total) => `Total ${total} items`
                  // }}
                  // columnTitle

                  // expandable={{
                  //   expandedRowRender: (record) => (
                  //     <div
                  //       style={{
                  //         backgroundColor: "#eeeeee",
                  //       }}
                  //     >
                  //       <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Permissions</div>
                  //       <Grid container style={{ display: "flex", justifyContent: "center" }}>
                  //         {record?.permission?.map((el, index) => (
                  //           <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                  //             <div style={{ textAlign: "center" }}>
                  //               {el},
                  //             </div>
                  //           </Grid>
                  //         ))}
                  //       </Grid>
                  //       <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Services</div>
                  //       <Grid container style={{ display: "flex", justifyContent: "center" }}>
                  //         {record?.services?.map((el, index) => (
                  //           <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                  //             <div style={{ textAlign: "center" }}>
                  //               {el},
                  //             </div>
                  //           </Grid>
                  //         ))}
                  //       </Grid>
                  //     </div>
                  //   ),

                  //   // columnTitle: <div style={{ width: "200px", display: "flex", justifyContent: "center" }}>View Permission</div>,
                  // }}
                  // onChange={handleTableChange}
                  />
                </>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default BranchReport;
