import Card from "@mui/material/Card";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

let myKey = 0;
const PieCard = (props) => {
    myKey++;
    const { data, labelArr, headings, myIcon, isPercent, notShowData } = props.data;
    const sum = data.datasets[0].data.reduce((total, num) => total + num, 0);
    return (
        <Card style={{ height: "100%" }}>
            <div style={{ height: "100%", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ height: "100%", width: "45%" }}>
                    <div style={{ height:"100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "0 10px 0 10px" }}>
                            <div style={{ fontSize: "14px", fontWeight: "bold", width: "90%" }}>{headings}</div>
                            <div >
                                {myIcon}
                            </div>
                        </div>
                        <div style={{height:"90%", display: "flex", alignItems:"flex-end",marginLeft:"3%",marginBottom:"2%" }}>
                            <div>
                                {
                                    data.datasets[0].backgroundColor.map((el, index) => (
                                        <div key={`donut01${index}${myKey}`} style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                                            <div style={{ width: "10px", height: "10px", backgroundColor: el, marginRight: "7px" }}></div>
                                            <div style={{ fontSize: labelArr[index].fontSize || "14px", color: "#818080", marginRight: "10px", maxWidth: "70%", width: "fit-content" }}>
                                                {isPercent && <span style={{ fontWeight: isPercent.fontWeight || "normal", color: isPercent.fontWeight ? "black" : "inherit" }}>{((data.datasets[0].data[index] / sum) * 100).toFixed(2)}% </span>}
                                                {labelArr[index]}
                                            </div>
                                            {!notShowData && <div style={{ fontSize: labelArr[index].fontSize || "14px", fontWeight: "bold", width: "fit-content" }}>₹{data.datasets[0].data[index]}</div>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "55%" }}>
                    <Pie
                        // width={"100%"}
                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                },
                                datalabels:
                                {
                                    // formatter:(value,context)=>{
                                    //     // console.log(context);
                                    //     // context.chart.ctx.font="12px sans-serif";
                                    //     let str=context.chart.data.labels[context.dataIndex]+"\n";
                                    //     // context.chart.ctx.font="14px bold sans-serif";
                                    //     // str+=value;
                                    //     return str;
                                    // },
                                    display: true,
                                    color: "black",
                                    labels: {
                                        title: {
                                            formatter: (value, context) => {
                                                return context.chart.data.labels[context.dataIndex];
                                            },
                                            font: {
                                                weight: "normal",
                                            }
                                        },
                                        value: {
                                            formatter: (value, context) => {
                                                return "\n\n" + value;
                                            },
                                            font: {
                                                weight: "bold",
                                                size:"18px"
                                            }
                                        }
                                    },
                                    font: {
                                        size: "14px",
                                        style: "normal"
                                    },


                                }
                            }
                        }}
                        data={data}
                        plugins={[{
                            beforeDatasetsDraw: (chart, args, options) => {

                            }
                        }]}
                    />
                </div>
            </div>
        </Card>
    )
}
export default PieCard;