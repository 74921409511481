import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import { ConfigFile } from "../../services/ConfigFile";
import UBKSubmit from "../../components/UBKSubmit";
import toast, { Toaster } from "react-hot-toast";
import useSWR, { mutate as globalMutate } from "swr";
import { Table, Pagination, Popconfirm, Typography, Form, Select } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import IconButton from "@mui/material/IconButton";
import { Drawer } from "@mui/material";

import {
    getNewData,
    addData,
    deleteData,
    updateData,
    getadmindataUrl,
    rolestableListUrl,
    adminroleUpdateUrl
} from "../../Api/api";

import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
// import EditAdmin from "./EditAdmin ";
import UBKCheckbox from "../../components/UBKCheckbox";

const statusDropdown = [
    {
        label: "Active",
        value: "1"
    },
    {
        label: "Disabled",
        value: "0"
    }
]


const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const [selectVal, setSelectVal] = useState("");
    const handlChange = (value) => {
        console.log(value);
        setSelectVal(value);
    }
    return (
        <td {...restProps}>
            {editing ? (
                inputType === 'input' ? <MDInput name="name" defaultValue={record?.name} type="text" style={{ width: "180px" }} required="required" />
                    : <>
                    <input name="role_id" type="hidden" value={record?.id} />
                        <input name="status" type="hidden" value={selectVal ? selectVal : statusDropdown.find((el) => el.value === record?.is_active)?.value} />
                        <Select onChange={handlChange} defaultValue={statusDropdown.find((el) => el.value === record?.is_active)?.label} options={statusDropdown} style={{ width: "180px" }} />
                    </>

            ) : (
                <div style={{marginLeft:"15px"}}>{
                    dataIndex === "is_active" ?
                        record.is_active === "1" ?
                            <MDBox color="success">Active</MDBox>
                            :
                            <MDBox color="error">Disabled</MDBox>
                        : children
                }
                </div>
            )}
        </td>
    );
};

// ==========MAIN FUNCTION ======================



function Roles() {
    const [open, setOpen] = useState(false);
    const [id, selectedId] = useState();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;
    const cancel = () => {
        setEditingKey('');
    };
    const edit = (record) => {
        setEditingKey(record.id);
    };
    const {
        isLoading,
        error,
        data: newData,
        mutate,
    } = useSWR(rolestableListUrl, getNewData);


    const updateTodoMutation = async (url, upData) => {
        try {
            await mutate(updateData(url, upData));
            toast.success("Success! Updated item.", {
                duration: 1000,
                icon: "🚀",
            });
        } catch (err) {
            toast.error("Failed to update the item.", {
                duration: 1000,
            });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const url = "/permission/menu/editroles";
        updateTodoMutation(url, formData);
        e.target.reset();
        setEditingKey('');
        DrawerUpdate(false);
    };
    const handleUpdateStatus = async (record, status) => {
        const frmData = new FormData();
        frmData.append("role_id", record.id);
        frmData.append("status", status);
        updateTodoMutation(adminroleUpdateUrl, frmData);
    }

    const DrawerUpdate = (status) => {
        console.log("dsdsdsdsd", status);
        setOpen(status);
    };
    const save = async (key) => {
        setEditingKey('');
    }

    const columns = [
        {
            title: <div style={{ paddingLeft: "10px" }}>Roll Name</div>,
            dataIndex: "name",
            editable: true,
            // render: (item, record, index) => <div style={{ paddingLeft: "10px" }}>
            //     {record.name}
            // </div>,
        },
        {
            title: <div style={{ paddingLeft: "10px" }}>Status</div>,
            editable: true,
            dataIndex: "is_active",
            // render: (item, record, index) => <div style={{ paddingLeft: "10px" }}>
            //     {record.is_active === "1" ?
            //         <MDBox color="success">Active</MDBox>
            //         :
            //         <MDBox color="error">Disabled</MDBox>
            //     }
            // </div>,
        },
        {
            title: 'Action',
            editable: false,
            render: (item, record, index) => {
                const editable = isEditing(record);
                return (
                    <div style={{ fontSize: "11px" }}>
                        {editable ? (
                            <span>
                                <Typography.Link
                                    // onClick={() => save(record.id)}
                                    style={{
                                        marginRight: 8,
                                    }}
                                >
                                    <input className="btn btn-primary btn-sm pt-0 pb-0" type="submit" value="Save" color="primary" form="tableForm" />
                                </Typography.Link>
                                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                    <Typography.Link>
                                        Cancel
                                    </Typography.Link>
                                </Popconfirm>
                            </span>
                        ) : (
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                <IconButton >
                                    <BorderColorIcon color={editingKey !== '' ? "" : "success"} fontSize="small" />
                                </IconButton>
                            </Typography.Link>
                        )
                        }
                    </div>)
            },
        },

    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'is_active' ? 'select' : 'input',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const editClick = (id) => {
        selectedId(id);
        DrawerUpdate(true);
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
                <EditAdmin data={{ id, handleUpdate }} />
            </Drawer> */}
            <Toaster toastOptions={{ position: "top-center" }} />
            <MDBox pt={8} pb={1}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={1}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Role List
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <form id="tableForm" onSubmit={handleUpdate}>
                                    <Table
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        style={{ margin: "50px" }}
                                        columns={mergedColumns}
                                        bordered={true}
                                        rowKey={(record) => record.id}
                                        dataSource={newData?.data}
                                        loading={isLoading}
                                        pagination={false}
                                    />
                                </form>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Roles;
