import { forwardRef } from "react";
import { Checkbox } from 'antd';

const UBKCheckbox = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  // console.log("ssasas",""+rest.label),

  <div className="form-group">
    <div>
      <label className="labelfont">{rest.label}</label>
    </div>
    <Checkbox.Group {...rest} ref={ref} />




  </div>
));



export default UBKCheckbox;
