import { useState, useEffect, createContext, useContext } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { ConfigFile } from "../../services/ConfigFile";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import Swal from "sweetalert2";

import MDBox from "components/MDBox";
import IconButton from "@mui/material/IconButton";

import Grid from "@mui/material/Grid";

import { Drawer } from "@mui/material";

import { duration } from "../../secondary/CommonFuntion";
import { useRef } from "react";
import dayjs from "dayjs";
import { Empty } from 'antd';
import ExportData from "../../layouts/export/ExportData";

//image modal
import {
  makeStyles,
  Modal,
  Backdrop,
  Fade
} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red"
    }
  },
  img: {
    outline: "none"
  }
}));


const RowId = createContext();

const UBKPaginationTable = (props) => {
  const {
    columns,
    action,
    DrawerUpdate,
    editDesign,
    newData,
    Loading,
    error,
    deleteTodoMutation,
    open,
    ActionView,
    viewDetails,
    myType
  } = props.data;

  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [image1, setImage1] = useState("false");

  const handleClose = () => {
    setOpen1(false);
  };

  const handleImage = (value) => {
    setImage1(value);
    setOpen1(true);
    console.log(image1);
  };

  const [id, selectedId] = useState();

  const [passengersCount, setPassengersCount] = useState(0);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const tableRef = useRef(null);
  const path = ConfigFile.BaseUrl;

  // const actionView = [{
  //   key: "Edit",
  //   status: true
  // }, {
  //   key: "Delete",
  //   status: true
  // },
  // {
  //   key: "View",
  //   status: true
  // }];

  let rowCount = 0;
  const handlePageChange = (event, newPage) => {
    props.parentCallback(newPage);
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  //console.log("aaaaaaaaa",mutate)

  const getList = () => {
    return (
      <>
        <RowId.Provider value={id}>
          {editDesign}
          {/* <editDesign  data={{id:id, }}></editDesign>  */}
        </RowId.Provider>
      </>
    );
  };

  const exportData = async () => { };

  const editClick = (id, status) => {
    selectedId(id);
    // setOpen(status);
    DrawerUpdate(status);
  };

  let content;
  if (Loading) {
    content = <p>Loading...</p>;
  } else if (error) {
    content = <p>{error.message}</p>;
  } else if (newData?.data?.length === 0) {
    content = <Empty />;
  } else if (newData?.length === 1) {
    content = <p>Loading...</p>;
  } else {
    if (Boolean(newData)) {
      let getAlldata = newData;
      const pagee = getAlldata?.pagination;
      rowCount = pagee ? pagee?.total_rows : 0;
      if (!passengersCount && props.parentCallback) {
        setPassengersCount(rowCount);
      } else {
      }

      const allData = getAlldata?.data ? getAlldata.data : getAlldata;
      content = (
        // return (
        <>
          <Table  ref={tableRef} aria-labelledby="tableTitle" size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                {columns?.map((headCell, index) => (
                  <TableCell key={index + "clmn"}>{headCell?.name}</TableCell>
                ))}

                {action ? <TableCell>Action</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(allData) && allData?.map((passenger, index) => (
                <TableRow key={index + "passenger"}>
                  <TableCell padding="checkbox" style={{ fontSize: "12px" }}>
                    {controller.page * controller.rowsPerPage + index + 1}.
                  </TableCell>

                  {/* {columns?.map((headCell, index) => (
                    <TableCell key={index + "clmns"} >
                      {passenger[headCell?.selector]}
                    </TableCell>
                  ))} */}
                  {columns.map((headCell, index) => {
                    switch (headCell.selector) {
                      case "file01":
                        return (
                          <TableCell key={index + "clmns"}>
                            {passenger["documents"] ?
                              <div
                                onClick={(e) => handleImage(`${ConfigFile.ImageUrl}/branch/frontoffice/${passenger["documents"]}`)}
                                style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}>
                                view file
                              </div>
                              : <div>Not Uploaded</div>
                            }
                            {/* <img
                              src={`${ConfigFile.ImageUrl}/branch/frontoffice/${passenger["documents"]}`}
                              alt={"img"}
                              onClick={(e) => handleImage(`${ConfigFile.ImageUrl}/branch/frontoffice/${passenger["documents"]}`)}
                              className="img"
                            /> */}
                          </TableCell>
                        )
                      case "is_active":
                        return (
                          <TableCell key={index + "clmns"}>{
                            passenger["is_active"] === "yes" ? <span className="label label-success">Active</span>
                              : <span className="label label-danger">Inactive</span>
                          }
                          </TableCell>
                        )
                      case "Profile_status":
                        return (
                          <TableCell key={index + "clmns"}>
                            {
                              <span
                                className={`${passenger["Profile_status"] === "Pending"
                                  ? "label label-danger"
                                  : "label label-success"
                                  }`}
                              >
                                {passenger["Profile_status"]}
                              </span>
                            }
                          </TableCell>
                        );
                      case "follow_up_date":
                        return (
                          <TableCell key={index + "clmns"}>
                            {
                              <span
                                className={`${passenger["follow_up_date"] === dayjs().format("YYYY-MM-DD")
                                  ? "label label-success"
                                  : ""
                                  }`}
                              >
                                {passenger["follow_up_date"]}
                              </span>
                            }
                          </TableCell>
                        );
                      case "payment_status":
                        return (
                          <TableCell
                            key={index + "clmns"}

                          >
                            {passenger[headCell.selector] === "due" ? (
                              <span
                                onClick={() => viewDetails(passenger.id)}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                                className="label label-danger"
                              >
                                Due
                              </span>
                            ) : (
                              <span
                                onClick={() => viewDetails(passenger.id)}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                                className="label label-success"
                              >
                                Paid
                              </span>
                            )}
                          </TableCell>
                        );
                      case "fees_duration":
                        return (
                          <TableCell key={index + "clmns"}>
                            {
                              duration().find((el) => el.value === passenger[headCell.selector])
                                ?.label
                            }
                          </TableCell>
                        );
                      case "status":
                        return (
                          <TableCell
                            key={index + "clmns"}
                          >
                           {passenger[headCell.selector] === "due" ? (
                              <span
                                onClick={() =>
                                  viewDetails(passenger?.fk_staffID, passenger?.total_leave)
                                }
                                style={{ cursor: "pointer" }}
                                className="label label-danger"
                              >
                                Due
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  viewDetails(passenger?.fk_staffID, passenger?.total_leave)
                                }
                                style={{  cursor: "pointer" }}
                                className="label label-success"
                              >
                                Paid
                              </span>
                            )} 
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell key={index + "clmns"}>
                            {passenger[headCell.selector]}
                          </TableCell>
                        );
                    }
                  })}

                 <TableCell className="displayflex" >
                    {ActionView && ActionView[0]?.status ? (
                      <IconButton
                        onClick={() => {
                          switch (myType?.type) {
                            case "examEdit":
                              return myType.editFunc(passenger.id);
                            default:
                              return editClick(passenger.id, true)
                          }
                        }
                        }
                        //style={{ marginRight: "5px" }}
                      >
                        <BorderColorIcon color="success" fontSize="small" />
                      </IconButton>
                    ) : null}

                    {ActionView && ActionView[1]?.status ? (
                      <IconButton onClick={() => deleteTodoMutation(passenger.id)}>
                        <DeleteOutlineIcon color="error" fontSize="small" />
                      </IconButton>
                    ) : null}

                    {ActionView && ActionView[2]?.status ? (
                      <IconButton onClick={() => {
                        switch (myType) {
                          case "student_result":
                            return viewDetails(passenger);
                          default:
                            return viewDetails(passenger.id)
                        }
                      }}>
                        <VisibilityIcon sx={{ color: "#3892ee" }} fontSize="small" />
                      </IconButton>
                    ) : null}
                  </TableCell> 

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      );
    } else {
      content = <Empty />;
    }
  }

  return (
    <Box sx={{ width: "100% " }}>
      <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
        {getList()}
      </Drawer>

      <Modal
        className={classes.modal}
        open={open1}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        
        <Fade in={open1} timeout={500} className={classes.img}>
          <div style={{ backgroundColor: "transparent",height: "fit-content", width: "80%", position: "relative" }}>
            <img
              src={image1}
              alt="asd"
              style={{ maxWidth: "100%" }}
            />
            <IconButton onClick={handleClose} sx={{ position: "absolute", top: "-28px", right: "-28px" }}>
              <CloseIcon color="white" className="closeButton1" />
            </IconButton>
          </div>
        </Fade>
      </Modal>
      <Card>
        <MDBox>
          <Grid container spacing={1} justifyContent={"space-between"}>
            <Grid item xs={12} md={3}>
              {/* <TextField
                label=""
                variant="standard"
                size="small"
                placeholder="Filter Table"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon fontSize="small" style={{ color: "#5080f8" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Grid>
            <Grid item xs={12} md={12}>
            <ExportData data={{tableRef}}></ExportData>
              {/* <Grid container spacing={1}>
                <Grid item xs={4}>
                  <IconButton onClick={() => exportData()}>
                    <LocalPrintshopOutlinedIcon fontSize="small" style={{ color: "#5080f8" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <IconButton>
                    <PictureAsPdfOutlinedIcon fontSize="small" style={{ color: "#5080f8" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <IconButton>
                    <ContentCopyOutlinedIcon fontSize="small" style={{ color: "#5080f8" }} />
                  </IconButton>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </MDBox>

        {content}
        {newData && props.parentCallback &&
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={controller.page}
            // page={controller.page}
            count={rowCount}
            //  count={passengersCount}
            rowsPerPage={controller.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Card>
    </Box>
  );
};

export default UBKPaginationTable;
export { RowId };
