import axios from "axios";
// import { url } from "../services/config";
import { ConfigFile } from "../services/ConfigFile";

// export const sectionlistUrl = "/branchapi/academy/sectionlist";
// export const classTablelistUrl = "branchapi/academy/classestableList";

// export const sectionsUrl = "/branchapi/academy/sectiontableList";
// export const holidayUrl = "/branchapi/academy/holidayList";
// export const admissionEnquiry = "/branchapi/frontoffice/enquirytablelist";
// export const referenceUrl = "/branchapi/Frontoffice/referencedropdown";
// export const sourceUrl = "/branchapi/Frontoffice/frontofficegetSource";
// export const classUrl = "/branchapi/academy/classdropdownlist";
// export const batchUrl = "/batch/findbatchByClass";
// // export const sectionUrl = "/branchapi/academy/sectionlist";
// export const subjectUrl = "/branchapi/Academy/getClassSubjectdropdown";
// export const sectionUrl = "/branchapi/academy/sectionoftheClass";
// export const purposeUrl = "/branchapi/frontoffice/purposedropdown";
// export const visitorListUrl = "/branchapi/frontoffice/visitortablelist";
// export const visitorAddtUrl = "/branchapi/frontoffice/addvisitors";
// export const generalcallUrl = "/branchapi/frontoffice/generalcalltablelist";
// export const complainUrl = "branchapi/frontoffice/complainttablelist";
// export const complainTypeUrl = "/branchapi/frontoffice/complainttypedropdown";
// export const PostalReceiveTableUrl = "/branchapi/frontoffice/postalrecivetablelist";
// export const PostalDispatchTableUrl = "branchapi/frontoffice/postaldispatchtablelist";
// export const ComplainShowData = "branchapi/frontoffice/complaintShowdata";
// export const purposeTableUrl = "branchapi/Frontoffice/purposetablelist";
// export const complaint_typeTableUrl = "branchapi/Frontoffice/complaint_typetablelist";
// export const sourceTableUrl = "branchapi/Frontoffice/sourcetablelist";
// export const referencTableUrl = "branchapi/Frontoffice/referencetablelist";

// export const assignSubjectUrl = "/branchapi/Academy/assign_class_subjects";
// export const courseDropdown = "/branchapi/academy/coursedropdown";
// export const studentsearchby = "/branchapi/student/studentsearchbyKeywords";
// export const studentsearcbyClasshby = "/branchapi/student/studentsearchbyClassSection";
// export const searchfeesByClassBatchDueDateUrl = "/fess_managment/FessStatement/searchfeesByClassBatchDueDate";
// export const FeesDetailsUrl = "/fess_managment/FessStatement/getStudentFeesDetails";
// export const searchfeesByDueDatUrl = "/fess_managment/FessStatement/searchfeesByDueDateStatus";

// // export const searchfeesByClassBatchDueDateUrl = "/fess_managment/FessStatement/searchfeesByClassBatchDueDate";
// export const studentLoginTable = "/student/studentDetails/studentLoginCredentialList";
// export const feestypeUrl = "/branchapi/feesCollection/feesTypeDropdown";
// export const sessionListUrl = "/branchapi/generalSettings/sessiondropdown";
// export const feestypeDurationUrl = "/branchapi/feesCollection/feestypeDuration";
// export const feesMasterTable = "/branchapi/feesCollection/feesmasterTable";
// export const feesMasterShowdataUrl = "/branchapi/feesCollection/feesMasterShowdata";
// export const deletefeesMasterUrl = "/branchapi/feesCollection/deletefeesMaster";

// export const addFeesTypeUrl = "/branchapi/feesCollection/addfeestype";
// export const feesTypeListUrl = "/branchapi/feesCollection/feesTypeTable";
// export const getFeesTypeById = "/branchapi/feesCollection/feesTypeShowdata";

// export const addFeesDiscount = "/branchapi/feesCollection/addfeesdiscount";
// export const deleteFeesDiscount = "/branchapi/feesCollection/deletefeesdiscount";
// export const editFeesDiscount = "/branchapi/feesCollection/editfeesdiscount";
// export const feesDiscountList = "/branchapi/feesCollection/fetchfeesdiscount";
// export const feesDiscountById = "/branchapi/feesCollection/fetchfeesdiscountbyid";

// export const sessionDropdown = "/branchapi/generalSettings/sessiondropdown"
// //export const sessionTableUrl="branchapi/generalSettings/sessiontable";
// export const sessionTableUrl="branchapi/generalSettings/sessiontable";
// export const updateSession="branchapi/generalSettings/setsession";
// export const addSession="/branchapi/generalSettings/addsession";
// export const editSession="branchapi/generalSettings/editsession";
// export const feesgroupDropdownUrl="/branchapi/feesCollection/feesgroupDropdown";

// export const payment_reciptUrl="/fess_managment/FessStatement/payment_recipt";
// export const payment_ReciptDetailsUrl="/fess_managment/FessStatement/getReciptDetailsFeesMaster";

// export const feesGroupTable="/branchapi/feesCollection/feesgroupTable";
// export const feesgroupShowdataUrl="/branchapi/feesCollection/feesgroupShowdata";
// export const getClassSubjectUrl="/branchapi/Academy/getClassSubject";
// export const classSubjectdropdownUrl="/branchapi/Academy/getSubjectsbyClassaslablevalue"
// export const examdropdownUrl="/exam/exam/examdropdown";
// export const stafflistforAttendenceUrl="/staff/attendence/stafflistforAttendence";
// export const addattendencetoStaffbyBranchUrl="/staff/attendence/addattendencetoStaffbyBranch";
// export const staffmonthlyAttendenceReportUrl="/AttendenceReport/staffmonthlyAttendenceReport";
// export const batchDataUrl="/branchapi/academy/batchdropdown";
// export const studentdataforStep1Url="/branchapi/student/studentdataforStep1";
// export const categoryDropdownUrl="/branchapi/academy/categoryDropdown";
// export const studentdataforStep2Url="/branchapi/student/studentdataforStep2";
// export const getstudentMarklistUrl="/exam/mark/getstudentMarklist";
// export const studentHistoryUrl="/branchapi/student/studentHistory";
// export const subcategoryDropdownStore="/accounts/category/subcategoryDropdown";
// export const studentsearchByNameID="/branchapi/library/studentsearchByNameID";
// export const booksearchBybookname="/branchapi/library/booksearchBybookname";
// export const returnedLibrarybook="/branchapi/library/returnedLibrarybook";
// export const issueLibrarybook="/branchapi/library/issueLibrarybook";
// export const yearlyAttendenceReportbyStuIDUrl="/attendenceReport/yearlyAttendenceReportbyStuID";
// export const studentmonthlyAttendenceUrl="/AttendenceReport/studentmonthlyAttendencebyclass";
// export const getstudentDetailsByIdUrl= "/student/StudentDetails/getstudentDetailsById";
// export const getStududentMonthlyByIdUrl="/AttendenceReport/getStududentMonthlyById";
// export const getStaffMonthlyByIdUrl="/AttendenceReport/getStaffMonthlyById";
export const menulistUrl="/superAdmin/menulist";
export const roleslistUrl="/superAdmin/roleslistfosuperadmin";
export const adminDropdownUrl="/superAdmin/adminDropdown";
export const getadmindataUrl="/superAdmin/getadmindata";
export const getroleDropDownUrl="/permission/menu/getroleDropDown";
export const getMenuandSubmenuUrl="/admin/admin/getMenuandSubmenu";
export const addBranchPermissionUrl="/permission/menu/addBranchPermission";
export const getbranchPermissionDataUrl="/permission/menu/getbranchPermissionData";
export const rolestableListUrl="/permission/menu/rolestableList";
export const adminroleUpdateUrl="/permission/menu/adminroleUpdate";
export const branchTableUrl="/admin/admin/branchTable";
export const getbranchdataUrl="/admin/admin/getbranchdata";
export const getAdminbranchDataUrl="/superAdmin/getAdminbranchData";

// let jsonString = JSON.parse(localStorage.getItem("Userdata"));
// const { branch_id } = jsonString;

const delay = () => new Promise((res) => setTimeout(() => res(), 800));

const baseUrl = axios.create({
  baseURL: ConfigFile.BaseUrl,
});

 const token = localStorage.getItem("token");

export const getNewData = async (url) => {
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  console.log("__________getNewData_____", url);
  // const prm=url.includes("?")?"&":"?";
  const response = await baseUrl.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return response?.data;
};

export const addData = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  console.log("ddddfdfdfd", response.data);
  if (response.data?.Status == "Success") {
    return response?.data?.data;
  } else {
    throw new Error(response.data?.msg);
  }
};

export const addDataTigget = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
 
    return response?.data;
 
};
export const addDataTiggetGET = async (url, data) => {
  const newdata=data?.arg
  const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  const prm=url.includes("?")?"&":"?";
  const response = await baseUrl.get(url+prm+"session_id="+generalSettings?.session_id, {

   params:newdata ,
    headers: {
      Authorization: token,
    },
  });
 
    return response?.data;
 
};

export const sendRequest = async (url,  data ) => {

 // console.log("eeeeeeeeeeeeeeeee", JSON.stringify(arg))
  // return false;
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, JSON.stringify(data.arg), {
    headers: {
      Authorization: token,
    },
  });
 
  if (response.data?.Status == "Success") {
    return response?.data?.data;
  } else {
    throw new Error(response.data?.msg);
  }
}


export const updateData = async (url, data) => {
  await delay();
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });

  console.log("aaaaaaaaa", response?.data);
  return response?.data;
  //return response?.data
};

export const getDataById = async (url, data) => {
  //  await delay()
  const token = localStorage.getItem("token");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

export const deleteData = async (url, id) => {
  // await delay()
  const token = localStorage.getItem("token");
  const response = await baseUrl.delete(`${url}/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};