// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";


// TABLE
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";

// DROPDOWN
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


// ALERT
import Swal from "sweetalert2";

// API
// import { url } from "../../services/config";


// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Paper from "@mui/material/Paper";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";


// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

import {
  addData, getNewData,
  getMenuandSubmenuUrl, roleslistUrl,
  getroleDropDownUrl, addDataTigget,
  updateData, addBranchPermissionUrl,
  getbranchPermissionDataUrl
} from "../../Api/api";
import { useRef } from "react";
import { NativeSelect } from "@mui/material";
import { Table, Pagination, Checkbox, Row, Collapse, Modal, Button as AntButton } from "antd";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import MDInput from "../../components/MDInput";
import { none } from "@hookstate/core";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const MDInput = forwardRef(({...rest }, ref) => (
//   // console.log("ssasas",""+rest.label),


//    <div className=''>
//      <label className="labelfont">{rest.label}</label>
//      {/* <Input className="form-control"   placeholder={rest.label} {...rest} ref={ref} /> */}
//      <input className="form-control"  {...rest} ref={ref} />
//    </div>
// ));

// const MycheckBox=({label,value})=>{
//   const [checkedList, setCheckedList] = useState([]);
//   // const checkAll = plainOptions.length === checkedList.length;
//   // const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
//   const onCheckAllChange = (e) => {
//     // setCheckedList(e.target.checked ? plainOptions : []);
//   };
//   <Checkbox {...rest} ref={ref} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
//         {label}
//   </Checkbox>
// }


function Permission() {
  // API

  const formRef = useRef(null);
  // const resetbtn = useRef(null);


  // HOOKS FOR FORMS
  const [isSelected, setIsSelected] = useState(false);
  const [formKey, setFormKey] = useState(false);
  const [selectUrl, setSelectUrl] = useState(false);
  // const [checkedList, setCheckedList] = useState([]);
  // const checkAll = formRef.current.length === checkedList.length;
  // const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  // const handleOk = () => {
  //   // setOpen(false);
  //   modalFormRef.current.submit();
  // };
  const handleCancel = () => {
    setOpen(false);
  };


  const [formData, setFormData] = useState({
    role_id: "",
    permission: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // if(resetbtn?.current){
    //   resetbtn.current.click();
    // }
    setSelectUrl(`${getMenuandSubmenuUrl}?role_id=${event.target.value}`);
    setFormKey((prev) => !prev);
    setIsSelected(true);
  };

  const {
    isLoading: isLoadingRole,
    error: errorRole,
    data: adminDropDown,
  } = useSWR(getroleDropDownUrl, getNewData);

  const {
    isLoading: isLoadingMenu,
    error: errorMenu,
    data: menuItem,
    mutate: menuMutate
  } = useSWR(selectUrl ? selectUrl : adminDropDown ? getMenuandSubmenuUrl : null, (url) => getNewData(selectUrl ? selectUrl : url + "?role_id=" + adminDropDown?.data[0].value));

  // const {
  //   isLoading: isLoadingMenu,
  //   error: errorMenu,
  //   data: menuItem,
  //   mutate: menuMutate
  // } = useSWR("temp", (url) => []);


  // const {
  //   isLoading: isLoadingAdminData,
  //   error: adminDataError,
  //   data: adminData,
  //   mutate: mutateAdmin,
  // } = useSWR(selectUrl, getNewData);


  // Step 2: Define the handleSubmit function
  const checkKey = (key, index, formdata) => {
    return (formdata.has(`permission[${key}][submenu][${index}][add]`)
      || formdata.has(`permission[${key}][submenu][${index}][edit]`)
      || formdata.has(`permission[${key}][submenu][${index}][view]`)
      || formdata.has(`permission[${key}][submenu][${index}][delete]`)
    )
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    const frmData = new FormData(e.target);
    const subFormData = new FormData();
    let index1 = -1, index2 = 0, tempIndex = 0, tempIndex2 = 0, isFirstSubmenu = true, notHaveSubmenu = true, isPermission = false;
    frmData.forEach((value, key) => {
      // console.log(key);
      if (key === "role_id") {
        subFormData.append(key, value);
      }
      else if (key.includes("[menu_id]")) {
        isFirstSubmenu = true;
        tempIndex = value;
      }
      else if (key.includes("[submenu_id]")) {
        isPermission = false;
        if (checkKey(tempIndex, value, frmData)) {
          if (isFirstSubmenu) {
            index1++;
            index2 = 0;
          }
          else{
            index2++;
          }
          isFirstSubmenu=false;
          isPermission = true;
          tempIndex2 = value;
          const tempName = key.replace(`permission[${tempIndex}][submenu][${tempIndex2}]`, `permission[${index1}][submenu][${index2}]`);
          subFormData.append(`permission[${index1}][menu_id]`, tempIndex);
          subFormData.append(tempName, tempIndex2);
        }
      }
      else {
        if (isPermission) {
          notHaveSubmenu = false;
          const tempName = key.replace(`permission[${tempIndex}][submenu][${tempIndex2}]`, `permission[${index1}][submenu][${index2}]`);
          subFormData.append(tempName, value);
        }
      }
    }
    )
    if (notHaveSubmenu) {
      failedAlert("Please select any field");
      return;
    }
    try {
      const res = await mutate(selectUrl ? selectUrl : `${getMenuandSubmenuUrl}?role_id=${adminDropDown?.data[0].value}`, updateData(addBranchPermissionUrl, subFormData));
      if (res.Status === "Success") {
        successAlert();
      }
    }
    catch (err) {
      failedAlert("Failed");
      console.log(err)
    }


  };
  const failedAlert = (title) => {
    Swal.fire({
      icon: "error",
      title: title,
      showConfirmButton: true,
    });
  };

  const successAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };


  const checkFunc = (index) => {
    const inptArr = formRef.current[`permission[${index}][data][]`];
    for (let i = 0; i < inptArr.length; i++) {
      if (inptArr[i].checked) {
        return false
      }
    }
    return true;
  }


  const columns = [
    {
      title: 'Menu and Submenu',
      dataIndex: 'name',
      key: "name",
      render: (item, record, index) => (
        <Collapse items={itemFunc(record, index)} />
      ),
 
    }
  ]
  // const closureIndex=()=>{
  //   let index=-1;
  //   return ()=>{
  //     index=index+1;
  //     return index;
  //   }
  // }
  // const indexFunc=closureIndex();
  let myIndex = 0;
  const itemFunc = (data, index) => {
    return (
      [{
        label: data.name,
        key: `${index}`,
        forceRender: true,
        children: <Table
          style={{ marginLeft: "30px" }}
          columns={columnFunc(data.submenu ? myIndex++ : null)}
          bordered={true}
          // rowSelection={{
          //   ...rowSelection,
          //   checkStrictly,
          // }}
          rowKey="submenu_id"
          dataSource={data.submenu ? data.submenu : []}
          loading={isLoadingMenu}
        
          expandable={{
          
            childrenColumnName: "changed"
          }}
          pagination={false}
        />
      }]
    )
  }

  
  const columnFunc = (menu_index) => {
    return ([
      {
        title: 'Submenu',
        // dataIndex: 'name',
        // key: '1',
        render: (item, record, index) => {
          return (
            <div >{record?.name}
            </div>
          )
        },
        width:300
      },
      {
        title: "Add",
        render: (item, record, index) => (
          <>
            {index === 0 && <><input defaultValue={record.parent_id} type="text" name={`permission[${record.parent_id}][menu_id]`} style={{ display: "none" }} />
            </>}
            <input type="text" defaultValue={record.submenu_id} name={`permission[${record.parent_id}][submenu][${record.submenu_id}][submenu_id]`} style={{ display: "none" }} />
            <input type="checkbox" value={1} name={`permission[${record.parent_id}][submenu][${record.submenu_id}][add]`} defaultChecked={record.add_permission === "1"} />
          </>
        )
      },
      {
        title: "Edit",
        render: (item, record, index) => (
          <>
            <input type="checkbox" value={1} name={`permission[${record.parent_id}][submenu][${record.submenu_id}][edit]`} defaultChecked={record.update_permission === "1"} />
          </>
        )
      },
      {
        title: "View",
        render: (item, record, index) => (
          <>
            <input type="checkbox" value={1} name={`permission[${record.parent_id}][submenu][${record.submenu_id}][view]`} defaultChecked={record.view_permission === "1"} />
          </>
        )
      },
      {
        title: "Delete",
        render: (item, record, index) => (
          <>
            <input type="checkbox" value={1} name={`permission[${record.parent_id}][submenu][${record.submenu_id}][delete]`} defaultChecked={record.delete_permission === "1"} />
          </>
        )
      },
      // Table.EXPAND_COLUMN,

    ]
    )
  }

  const submitRole = async (e) => {
    e.preventDefault();
    const frmData = new FormData(e.target);

    try {
      const res = await mutate(getroleDropDownUrl, updateData("/permission/menu/addroles", frmData));
      if (res.Status === "Success") {
        successAlert();
      }
    }
    catch (err) {
      failedAlert(err.response.data.msg);
      console.log(err)
    }
    setOpen(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1} ml={3} pt={8} pb={1}>
        <form onSubmit={submitRole}>
          <Modal
            open={open}
            title="Add Role"
            onCancel={handleCancel}
            okText="Add Role"
            // onOk={handleOk}
            okButtonProps={{ htmlType: "submit", form: "modalForm" }}
          >
            <form id="modalForm" onSubmit={submitRole}>
              <MDInput
                type="text"
                name="name"
                label="Role Name*"
                required="required"
              />
            </form>
          </Modal>
        </form>
        <Grid container spacing={6}>
          {Array.isArray(adminDropDown?.data) &&
            <form ref={formRef} key={`formKey01${formKey}`} style={{ width: "100%" }} onSubmit={handleSubmit}>
              {/* <input type="reset" ref={resetbtn} style={{ display: "none" }} /> */}
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" >
                      Roles Permission
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3} pl={2} pr={2}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <MDBox >
                            <MDBox>
                              <FormControl fullWidth>

                                {/* <label className="labelfont">Role</label>
                                <NativeSelect
                                  defaultValue={""}
                                  inputProps={{
                                    name: 'role_id',
                                    id: 'admin01',
                                  }}
                                >
                                  <option value={""} disabled>Select</option>
                                  {adminDropDown?.data?.map((item, index) => (
                                    <option key={`role01${index}`} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </NativeSelect> */}
                                {Array.isArray(adminDropDown?.data) && <>
                                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Role"
                                    name="role_id"
                                    value={formData.role_id ? formData.role_id : adminDropDown.data[0]?.value}
                                    onChange={handleChange}
                                    sx={{ height: "44px" }}
                                  >
                                    {adminDropDown?.data?.map((item, index) => (
                                      <MenuItem key={`role01${index}`} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                                }
                              </FormControl>
                            </MDBox>
                          </MDBox>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <Tooltip title="Add Role" placement="top">
                            <IconButton onClick={showModal}>
                              <AddCircleOutlineIcon color="info" fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </MDBox>

                  {/* {!isLoadingMenu && ( */}
                  <MDBox pt={3} pl={2} pr={2}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} md={10}>
                          <Table
                            style={{ margin: "50px" }}
                            columns={columns}
                            bordered={true}
                            // rowSelection={{
                            //   ...rowSelection,
                            //   checkStrictly,
                            // }}
                            rowKey="menu_id"
                            dataSource={menuItem?.data}
                            loading={isLoadingMenu}
                            // expandable={{
                            // expandedRowRender: (record) => (
                            //   <div
                            //     style={{
                            //       backgroundColor: "#eeeeee",
                            //     }}
                            //   >
                            //     <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Permissions</div>
                            //     <Grid container style={{ display: "flex", justifyContent: "center" }}>
                            //       {record?.children?.map((el, index) => (
                            //         <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                            //           <div style={{ textAlign: "center" }}>
                            //             {el.name},
                            //           </div>
                            //         </Grid>
                            //       ))}
                            //     </Grid>
                            //   </div>
                            // ),

                            // columnTitle: <div style={{ width: "200px", display: "flex", justifyContent: "center" }}>View Permission</div>,
                            // }}
                            // onChange={handleTableChange}
                            expandable={{
                              // defaultExpandedRowKeys:["index"],
                              // expandRowByClick:true
                              // rowExpandable:(record)=>false,
                              childrenColumnName: "changed"
                            }}
                            pagination={false}
                          />
                        </Grid>

                        <Grid item xs={12} md={1}></Grid>
                      </Grid>
                    </Box>
                  </MDBox>
                  {/* )} */}
                  <MDBox pt={3} mb={2}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}></Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          {!isLoadingMenu &&
                            <Button
                              type="submit"
                              variant="contained"
                              name="subBtn"
                              // onClick={handleSubmit}
                              style={{ color: "white", fontSize: "14px", minWidth: "162px" }}
                            >
                              Save
                            </Button>
                          }

                        </Grid>
                        <Grid item xs={3}></Grid>
                      </Grid>
                    </Box>
                  </MDBox>
                </Card>
              </Grid>
            </form>
          }
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Permission;
