// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import {useState as useHookState} from '@hookstate/core';
import GolobalState from "../../components/GolobalState";

// DROPDOWN
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";

// ALERT
import MDSnackbar from "components/MDSnackbar";

// API
// import { url } from "services/config";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Paper from "@mui/material/Paper";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useRef } from "react";
import { ConfigFile } from "../../services/ConfigFile";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddSchool() {
  // API
  const path = ConfigFile.BaseUrl;
  const token = sessionStorage.getItem("token");
  // HOOKS FOR FORMS
  const [selectedItems, setSelectedItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [imgFile,setImgFile]=useState("");
  const [formData, setFormData] = useState({
    school_name: "",
    email: "",
    password: "",
    contactPersonName: "",
    location: "",
    contact: "",
    file: "",
    services: [],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const {TotalItem, TotalCost} = useHookState(GolobalState);
  console.log("fdgfdg",TotalItem.get())

  // Step 2: Define the handleSubmit function
  const handleSubmit = () => {
    console.log(formData);

    // const { school_name, email, password, contactPersonName, location, contact, file } = {
    //   ...formData,
    // };
    // if (
    //   !school_name ||
    //   !email ||
    //   !password ||
    //   !contactPersonName ||
    //   !location ||
    //   !contact ||
    //   !file
    // ) {
    //   alert("hello");
    // }


    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    // POST request to send the object
    fetch(`${path}superAdmin/add_branch`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify([formData]),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("hellooooo---===>", data);
        console.log(typeof data);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error:", error);
      });

    // handleReset();
  };

  const handleReset = () => {
    setFormData({
      school_name: "",
      email: "",
      password: "",
      contactPersonName: "",
      location: "",
      contact: "",
      file: "",
    });
    setSelectedItems([]);
  };

  // Step:3 ALERT CODE WRITTEN BELOW
  const [successSB, setSuccessSB] = useState(false);
  const [title, setTitle] = useState("School Succesfully Added");
  const [color, setColor] = useState("success");
  const [icon, setIcon] = useState("check");

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content=""
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  // Step:4 fils Submission Hadnling
  const handleFileChange = async (event) => {
    const image = new FormData();
    image.append("image", event.target.files[0],event.target.name);
    try {
      const response = await axios.post(`${path}superAdmin/upload_logo`, image, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setImgFile(response.data.data);
      // setFormData((prevData) => ({
      //   ...prevData,
      //   file: response.data.data,
      // }));
    } catch (error) {
      console.log("upload_file..--.", error);
      throw new Error("Some internal error occurred");
    }
  };

  // ======================DROP DOWN ==================

  const fetchServices = async () => {
    try {
      const response = await fetch(`${path}/superAdmin/servicelist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMenuItems(data.data);
    } catch (error) {
      console.error("Error sending data:", error);
      return error;
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleDChange = (event) => {
    setSelectedItems(event.target.value);
    setFormData((prev) => ({
      ...prev,
      services: event.target.value,
    }));
  };
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Add Admin
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} pl={2} pr={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} md={3}></Grid>
                      <Grid item xs={12} md={6}>
                        {" "}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {" "}
                            <TextField
                              label="Admin Name"
                              id="outlined-size-small"
                              name="school_name"
                              value={formData.school_name}
                              onChange={handleChange}
                              size="small"
                              type="email"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Email"
                              id="outlined-size-small"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              size="small"
                              type="email"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Password"
                              id="outlined-size-small"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              size="small"
                              fullWidth
                            />
                          </Grid>{" "}
                          <Grid item xs={12}>
                            <TextField
                              label="Branch Location"
                              id="outlined-size-small"
                              size="small"
                              name="location"
                              value={formData.location}
                              onChange={handleChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="contactPersonName"
                              value={formData.contactPersonName}
                              onChange={handleChange}
                              label="Contact Persone Name"
                              id="outlined-size-small"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="outlined-size-small"
                              label="Contact Number"
                              type="number"
                              size="small"
                              name="contact"
                              value={formData.contact}
                              onChange={handleChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              onChange={handleFileChange}
                              value={imgFile}
                              label="Upload Logo"
                              id="outlined-size-small"
                              size="small"
                              type="file"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: {
                                  accept: "image/*",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}></Grid>
                    </Grid>
                  </Box>
                </MDBox>
                <MDBox pt={3} pl={2} pr={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControl sx={{ height: "44px", minWidth: 120 }} fullWidth>
                          <InputLabel>Select Services</InputLabel>
                          <Select
                            multiple
                            value={selectedItems}
                            label="Select Services"
                            onChange={handleDChange}
                            renderValue={(selected) => selected.join(", ")}
                            style={{ height: "44px" }}
                          >
                            {menuItems.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={selectedItems.indexOf(item.id) > -1} />
                                <ListItemText
                                  primary={item.service_name}
                                  style={{ fontSize: "10px" }}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </Box>
                </MDBox>
                <MDBox pt={3} mb={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}></Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={handleSubmit}
                          style={{ color: "white", fontSize: "14px", minWidth: "162px" }}
                        >
                          <AddIcon /> &nbsp; Add Admin
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        {/* <Button
                          variant="contained"
                          onClick={handleReset}
                          style={{
                            color: "white",
                            minWidth: "162px",
                            fontSize: "14px",
                            background: "#e03d3d",
                          }}
                        >
                          <RotateLeftIcon /> &nbsp; Reset
                        </Button> */}
                      </Grid>
                      {/* <Grid item xs={3}>
                        {renderSuccessSB}
                      </Grid> */}
                    </Grid>
                  </Box>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </form>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddSchool;
