import React from 'react'
import { useNavigate,Navigate } from "react-router-dom";
function ProtectedRoute({ isSignedIn, children }) {
  console.log("asasasa","saas"+isSignedIn);
  const navigate = useNavigate();
  console.log("loginStatus",localStorage.getItem("LoginStatus"));
  if (!localStorage.getItem("LoginStatus")) {
    return <Navigate to="/" replace />
  }
  return children
}
export default ProtectedRoute