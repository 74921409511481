import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";

import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";

import UBKSubmit from "../../components/UBKSubmit";

import { RowId } from "../../components/Table/UBKPaginationTable";

// API
import { ConfigFile } from "../../services/ConfigFile";

import useSWR from "swr";
//import MDButton from "components/MDButton";

import { getNewData, getbranchdataUrl as cacheKey } from "../../Api/api";
import UBKCheckbox from "../../components/UBKCheckbox";
import UBKfile from "../../components/UBKfile";
import { Select } from "antd";

const statusDropdown = [
    {
        label: "Active",
        value: "Active"
    },
    {
        label: "Disabled",
        value: "Deactive"
    }
]

function EditBranch(props) {
    const { handleUpdate, id: idd } = props.data;
    // const idd = useContext(RowId);
    const path = ConfigFile.BaseUrl;

    const [image, setImage] = useState("");
    const [selectVal, setSelectVal] = useState("");

    const handlChange = (value) => {
        setSelectVal(value);
    }

    const {
        isLoading,
        error,
        data: newData,
        mutate,
    } = useSWR(`${cacheKey}?branch_id=${idd}`, getNewData);

    const {
        isLoading: menuLoading,
        error: menuError,
        data: permissionMenu
    } = useSWR("/admin/Admin/getadminMenu", getNewData);

    const getCallbackSingle = (action) => (event) => {
        if (event == null || event == "undefined" || event == "") {
        } else {
            if (event[0].status === "done") {
                var name = event[0].response.data;
                setImage(name);
            } else {
            }
        }
    };

    const {
        isLoading: loadingServices,
        error: serviceError,
        data: serviceList,
    } = useSWR("/admin/Admin/servicelist", getNewData);


    return (
        <MDBox pt={8} pb={1} style={{ width: 350 }}>
            <Grid container spacing={6} >
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={1}
                            px={1}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Edit Branch
                            </MDTypography>
                        </MDBox>
                        <MDBox p={3}>
                            {!isLoading &&
                                <form onSubmit={handleUpdate}>
                                    <Grid container spacing={3}>
                                        <MDInput
                                            type="text"
                                            defaultValue={idd}
                                            name="id"
                                            required="required"
                                            className="noDisplay"
                                        />
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <MDInput
                                                        defaultValue={newData?.data[0]?.branch_name}
                                                        required="required"
                                                        name="school_name"
                                                        type="text"
                                                        label="Branch Name"
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <MDInput
                                                        defaultValue={newData?.data[0]?.email}
                                                        required="required"
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <MDInput
                                                        defaultValue={newData?.data[0]?.location_name}
                                                        name="location"
                                                        type="text"
                                                        label="Location Name"
                                                        required={"required"}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <MDInput
                                                        defaultValue={newData?.data[0]?.conatct_person}
                                                        name="contactPersonName"
                                                        type="text"
                                                        label="Contact Person"
                                                        required={"required"}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <MDInput
                                                        defaultValue={newData?.data[0]?.conatct_number}
                                                        name="contact"
                                                        type="tel"
                                                        label="Contact Number"
                                                        required={"required"}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <UBKfile
                                                        url={`${path}/superAdmin/upload_logo`}
                                                        numberImage={1}
                                                        oldImage={newData?.data[0]?.branch_logo ? `/branch/logo/${newData?.data[0]?.branch_logo}` : null}
                                                        parentCallback={getCallbackSingle()}
                                                    />
                                                    <MDInput
                                                        className="noDisplay"
                                                        type="text"
                                                        name="file"
                                                        onChange={() => { }}
                                                        value={image ? image : newData?.data[0]?.branch_logo ? newData?.data[0]?.branch_logo : ""}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <label style={{display:"block"}} className="labelfont">Status</label>
                                                    <input name="status" type="hidden" value={selectVal ? selectVal : statusDropdown.find((el) => el.value === newData?.data[0]?.status)?.value} />
                                                    <Select dropdownStyle={{zIndex:"99999"}} onChange={handlChange} defaultValue={statusDropdown.find((el) => el.value === newData?.data[0]?.status)?.label} options={statusDropdown} style={{ width: "180px" }} />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    <UBKCheckbox
                                                        defaultValue={newData?.data[0]?.services}
                                                        label="Services"
                                                        options={Array.isArray(serviceList?.data) ? serviceList.data : []}
                                                        name="services[]"
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MDBox>
                                                <MDBox>
                                                    {newData && permissionMenu &&
                                                        <UBKCheckbox
                                                            label="Permissions"
                                                            defaultValue={newData?.data[0]?.permission}
                                                            options={Array.isArray(permissionMenu) ? permissionMenu : []}
                                                            name="permission[]"
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "1fr 1fr"
                                                            }}
                                                        />
                                                    }
                                                </MDBox>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="center">
                                                <Grid item>
                                                    <MDBox>
                                                        <UBKSubmit type="submit" value="Save" color="primary" />
                                                    </MDBox>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            }
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditBranch;
