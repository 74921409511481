import Card from "@mui/material/Card";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels);

let myKey=0;
const DonutCard = (props) => {
    myKey++;
    const { data, labelArr, headings, myIcon,isPercent,notShowData }=props.data;
    const sum=data.datasets[0].data.reduce((total,num)=>total+num,0);
    return (
        <Card style={{height:"100%"}}>
            <div style={{ height: "100%", padding: "10px" }}>
                <div style={{ height: "25%", display: "flex", justifyContent: "space-between", margin: "0 10px 0 10px" }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold", width: "85%" }}>{headings}</div>
                    <div >
                        {myIcon}
                    </div>
                </div>
                <div style={{ height: "75%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{flexGrow:"1"}}>
                        {
                            data.datasets[0].backgroundColor.map((el, index) => (
                                <div key={`donut01${index}${myKey}`} style={{ display: "flex", alignItems: "center", margin: "0" }}>
                                    <div style={{ width: "10px", height: "10px", backgroundColor: el, marginRight: "7px" }}></div>
                                    <div style={{ fontSize: labelArr[index].fontSize || "14px", color: "#818080", marginRight: "10px", maxWidth: "70%",width:"fit-content" }}>
                                        {isPercent && <span style={{fontWeight:isPercent.fontWeight || "normal",color:isPercent.fontWeight?"black":"inherit"}}>{((data.datasets[0].data[index] / sum)*100).toFixed(2)}% </span>}
                                         {labelArr[index].label}
                                         </div>
                                    {!notShowData && <div style={{ fontSize: labelArr[index].fontSize || "14px", fontWeight: "bold" ,width:"fit-content" }}>₹{data.datasets[0].data[index]}</div>}
                                </div>
                            ))
                        }
                    </div>
                    <Doughnut options={{
                        plugins:{
                        legend:{
                            display:false
                        },
                        datalabels:{
                            display:false
                        }
                        
                    }
                }} plugins={[{
                        id: `MyPlugin${myKey}`,
                        beforeDatasetsDraw: (chart, args, options) => {
                            const { ctx, data } = chart;
                            ctx.save();
                            ctx.font = "13px sans-serif";
                            ctx.fillStyle = 'black';
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            const xcenter = chart.getDatasetMeta(0).data[0].x;
                            const ycenter = chart.getDatasetMeta(0).data[0].y;
                            ctx.fillText("Total", xcenter, ycenter - 4);
                            ctx.font = "bold 13px sans-serif";
                            ctx.fillText("9300", xcenter, ycenter + 9);
                        }
                    }
                    ]} data={data}/>
                </div>
            </div>
        </Card>
    )
}
export default DonutCard;