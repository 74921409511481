import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Button, TextField } from "@material-ui/core";
import { Input } from 'antd';

const MDInput = forwardRef(({...rest }, ref) => (
  // console.log("ssasas",""+rest.label),

      
   <div className=''>
     {rest.label && <label className="labelfont">{rest.label}</label>}
     {/* <Input className="form-control"   placeholder={rest.label} {...rest} ref={ref} /> */}
     <input className="form-control"   placeholder={rest.label} {...rest} ref={ref} />
   </div>
));

// // Setting default values for the props of MDInput
// MDInput.defaultProps = {
//   error: false,
//   success: false,
//   disabled: false,
// };

// // Typechecking props for the MDInput
// MDInput.propTypes = {
//   error: PropTypes.bool,
//   success: PropTypes.bool,
//   disabled: PropTypes.bool,
// };

export default MDInput;
