import { createState } from '@hookstate/core'
const GolobalState = createState({
  TotalItem: "dcfg",
  TotalCost: 0,
  Preferences: [],
  orderdata: [{deliveryCharge: {amount: 0, gst:0}}],
  AddresData: [],
  TimeSlot: '',
  canproceed: false,
});
export default GolobalState