import React from 'react'
import { useNavigate,Navigate } from "react-router-dom";
function LoginRoute({ isSignedIn, children }) {

  const navigate = useNavigate();
  console.log("asasasa",isSignedIn);
  console.log("asasasa",children);
  console.log("loginStatus_login",localStorage.getItem("LoginStatus"));

  if (localStorage.getItem("LoginStatus")) {
   return <Navigate to="/dashboard" replace />
  }
  return children
}
export default LoginRoute