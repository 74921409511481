import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// import { url } from "../../services/config";

const PaginationTable = (props) => {
  const { url, title, columns } = props.data;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const path = url.baseUrl;

  const fetchUsers = async (page, size = perPage) => {
    setLoading(true);

    // const response = await axios.get(`${path}/${url}?page=${page}&per_page=${size}`);

    // setData(response.data.data);
    // setTotalRows(response.data.pagination.total_rows);
    // setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  const handlePageChange = (page) => {
    console.log("sssssssss", page);
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const tableData = {
    columns,
    data,
  };

  return (
    <DataTableExtensions {...tableData}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
      />
    </DataTableExtensions>
  );
};
export default PaginationTable;
