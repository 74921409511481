import React from 'react';
import Swal from 'sweetalert2'
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
//Image upload modules
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
//import "antd/dist/antd.css";
import { ConfigFile } from "../../services/ConfigFile";
////import { Logger } from 'sass';
//import { LocalFlorist } from '@material-ui/icons';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const path = ConfigFile.BaseUrl;

class UBKfile extends React.Component {

  constructor(props) {
    super(props)

    // console.log("-bannerprops--",props)
    ///console.log("---111---",props.numberImage) ///1

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    };



    if (props.numberImage === 1) {
      //  console.log("asasasasasa",props.oldImage)
      if (props.oldImage) {
        const obj = { 'url': ConfigFile.ImageUrl + props.oldImage };
        this.state.fileList.push(obj);
      }


    }
    else if (props.numberImage > 1) {
      // console.log("=====12121==","-1-",props.oldImage.length);

      if (props.oldImage != undefined) {
        if (props.oldImage.length != 0) {
          props.oldImage.map(name => {
            const obj = { 'url': ConfigFile.ImageUrl + name };
            this.state.fileList.push(obj);
            // console.log("=====12121==","-2-",name);
          })
        }

      }


    }
    else {

    }

  }


  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You can only upload JPG/PNG file!',

      })
      //  message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Image must smaller than 2MB!',

      })
    }
    return isJpgOrPng && isLt2M;

  }

  handleCancel = () => this.setState({ previewVisible: false });
  //Image Preview
  handlePreview = async file => {
    //  console.log("1-----");
    if (!file.url && !file.preview) {
      // console.log("2----");
      file.preview = await getBase64(file.originFileObj);
    }

    // console.log("3----");
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });

    //  console.log("sdasdsad",""+JSON.stringify(this.state.fileList));
  };

  handleChange = ({ fileList }) => {
    //  console.log("=====1111===",fileList)
    this.setState({ fileList })
    this.props.parentCallback(fileList)
  }


  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: this.props.numberImage }}>Upload</div>
      </div>
    );
    return (
      <>
        <div>
          <label className="labelfont">Upload File</label>
        </div>
        <Upload

          action={this.props.url}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= this.props.numberImage ? null : uploadButton}



        </Upload>
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          {this.props.parentCallback(fileList)}
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>

      </>
    );
  }
}
export default UBKfile;
