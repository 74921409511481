import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import { Box } from "@mui/material";
import UBKSelect from "../../components/UBKSelect";
import UBKDate from "../../components/UBKDate";
import UBKTime from "../../components/UBKTime";
import UBKfile from "../../components/UBKfile";
// import Visitoredit from "layouts/EditPage/Visitoredit";
import { ConfigFile } from "../../services/ConfigFile";
import dayjs from "dayjs";
import UBKSubmit from "../../components/UBKSubmit";
import toast, { Toaster } from "react-hot-toast";
import useSWR from "swr";
import UBKPaginationTable from "../../components/Table/UBKPaginationTable";
import { Table, Pagination } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import { Drawer } from "@mui/material";

import {
  getNewData,
  addData,
  deleteData,
  updateData
} from "../../Api/api";

import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
import EditAdmin from "./EditAdmin ";
import UBKCheckbox from "../../components/UBKCheckbox";

// ==========MAIN FUNCTION ======================



function AddAdmin() {
  const path = ConfigFile.BaseUrl;
  const [classnme, setclassnme] = useState("");
  const [open, setOpen] = useState(false);
  const [img, setImage] = useState();
  const [id, selectedId] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const addPageIndex = (page) => {
    setController({
      ...controller,
      page: page,
    });
  };

  const actionView = [
    {
      key: "Edit",
      status: true,
    },
    {
      key: "Delete",
      status: true,
    },
    {
      key: "View",
      status: false,
    },
  ];

  const addMutation = async (url, newData) => {
    try {
      await mutate(addData(url, newData), addOptions(newData));

      toast.success("Success! Added new item.", {
        duration: 1000,
        icon: "🎉",
      });
    } catch (err) {
      toast.error("Failed to add the new item.", {
        duration: 1000,
      });
    }
  };

  const {
    isLoading: menuLoading,
    error: menuError,
    data: permissionMenu
  } = useSWR("/permission/Menu", getNewData);
  const {
    isLoading,
    error,
    data: newData,
    mutate,
  } = useSWR(`/superAdmin/admintable?page=${tableParams.pagination.current}&per_page=${tableParams.pagination.pageSize}`, getNewData);

  // const {
  //   isLoading:isLoadingAdminData,
  //   error,
  //   data: newdata,
  //   mutate,
  // } = useSWR("/api/superAdmin/admintable" + `?page=${controller.page + 1}&per_page=10`, getNewData);


  const deleteTodoMutation = async (id) => {
    const url = "/branchapi/frontoffice/deletevisitors";
    try {
      await mutate(deleteData(url, id), deleteTodoOptions(id));
      toast.success("Success! Deleted item.", {
        duration: 1000,
      });
    } catch (err) {
      toast.error("Failed to delete the item.", {
        duration: 1000,
      });
    }
  };

  const updateTodoMutation = async (url, upData) => {
    try {
      await mutate(updateData(url, upData));
      toast.success("Success! Updated item.", {
        duration: 1000,
        icon: "🚀",
      });
    } catch (err) {
      toast.error("Failed to update the item.", {
        duration: 1000,
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const url = "/superAdmin/editadmin";
    updateTodoMutation(url, formData);
    e.target.reset();
    DrawerUpdate(false);
  };

  const handleSubmit = (event) => {
    console.log("2222222222", "handleSubmit");
    event.preventDefault();
    const formData = new FormData(event.target);
    addMutation("/superAdmin/addAdmin", formData);
    event.target.reset();
  };

  const DrawerUpdate = (status) => {
    console.log("dsdsdsdsd", status);
    setOpen(status);
  };
  const onChange = (page) => {
    console.log(page);
    setTableParams((prev) => (
      {
        ...prev,
        pagination: {
          current: 1,
          pageSize: prev.pagination.pageSize,
        }
      }))
  };

  console.log("2222222222", "okkk");

  

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      // width: '12%',
    },
    {
      title: 'Email',
      dataIndex: 'email_id',
      // width: '30%',
      key: 'email_id',
    },
    // Table.EXPAND_COLUMN,
    {
      title: 'Action',
      // dataIndex: 'id',
      // width: '30%',
      // key: 'email_id',
      render: (item, record, index) => <div onClick={() => editClick(record.id)} style={{ fontSize: "11px" }}>
        {/* {!record?.menu_name ? */}
        <IconButton>
          <BorderColorIcon color="success" fontSize="small" />
        </IconButton>
        {/* : null */}
        {/* } */}
      </div>,
    },

  ];
  const editClick = (id) => {
    selectedId(id);
    // setOpen(status);
    DrawerUpdate(true);
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
        <EditAdmin data={{ id, handleUpdate }} />
      </Drawer>
      <Toaster toastOptions={{ position: "top-center" }} />
      <MDBox pt={8} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Add Admin
                  </MDTypography>
                </MDBox>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <UBKSelect
                            options={newPurpude}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            label="Purpose"
                            name="purpose"
                            required={true}
                            allowSelectAll={false}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid> */}
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput required="required" name="first_name" type="text" label="First Name" />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput required="required" name="last_name" type="text" label="Last Name" />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput name="email_id" type="email" label="Email" required={"required"} />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="password"
                            type="password"
                            label="Password"
                            required={"required"}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <UBKCheckbox
                        label="Permissions"
                        options={Array.isArray(permissionMenu) ? permissionMenu : []}
                        name="permission[]"
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                            name="phone"
                            type="tel"
                            label="Phone No."
                            required={"required"}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="branch_location"
                            type="text"
                            label="Branch Location"
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="branch_location"
                            type="contact_person_name"
                            label="Contact Person Name"
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <Box>
                            <UBKfile
                              url={`${path}/branchapi/frontoffice/uploadfrontofficeDocument`}
                              numberImage={1}
                              oldImage=""
                              parentCallback={getCallbackSingle()}
                            />
                            <MDInput
                              className="noDisplay"
                              type="text"
                              name="image"
                              defaultValue={img ? img : ""}
                            />
                          </Box>
                        </MDBox>
                      </MDBox>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <MDBox>
                            <UBKSubmit type="submit" value="Save" color="primary" />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Admin List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {!isLoading &&
                <>
                  <Table
                    style={{ margin: "50px" }}
                    columns={columns}
                    bordered={true}
                    // rowSelection={{
                    //   ...rowSelection,
                    //   checkStrictly,
                    // }}
                    rowKey={(record) => record.id}
                    dataSource={newData?.data}
                    loading={isLoading}
                    pagination={{
                      current: tableParams.pagination.current,
                      pageSize: tableParams.pagination.pageSize,
                      total: newData?.pagination?.total_rows,
                      showSizeChanger: true,
                      onChange: onChange,
                      onShowSizeChange: (current, pageSize) => {
                        setTableParams((prev) => (
                          {
                            ...prev,
                            pagination: {
                              current: 1,
                              pageSize: pageSize,
                            }
                          }))
                      },
                      showTotal: (total) => `Total ${total} items`
                    }}
                    // columnTitle

                    expandable={{
                      expandedRowRender: (record) => (
                        <div
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Permissions</div>
                          <Grid container style={{display:"flex",justifyContent:"center"}}>
                            {record?.permission?.map((el, index) => (
                              <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                                <div style={{ textAlign: "center" }}>
                                  {el},
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      ),

                      // columnTitle: <div style={{ width: "200px", display: "flex", justifyContent: "center" }}>View Permission</div>,
                    }}
                  // onChange={handleTableChange}
                  />
                </>
                 } 
                {/* <UBKPaginationTable
                  parentCallback={addPageIndex}
                  data={{
                    newData: newdata,
                    columns: columns,
                    action: true,
                    ActionView: actionView,
                    Loading: isLoading,
                    error: error,
                    editDesign: <EditAdmin data={{ handleUpdate }} />,
                    deleteTodoMutation: deleteTodoMutation,
                    open: open,
                    DrawerUpdate: DrawerUpdate,
                    viewDetails: "",
                  }}
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddAdmin;
