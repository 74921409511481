import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import MDInput from "../../components/MDInput";
import { Box } from "@mui/material";
import UBKSelect from "../../components/UBKSelect";
import UBKDate from "../../components/UBKDate";
import UBKTime from "../../components/UBKTime";
import UBKfile from "../../components/UBKfile";
// import Visitoredit from "layouts/EditPage/Visitoredit";
import { ConfigFile } from "../../services/ConfigFile";
import dayjs from "dayjs";
import UBKSubmit from "../../components/UBKSubmit";
import toast, { Toaster } from "react-hot-toast";
import useSWR, { mutate as globalMutate } from "swr";
import UBKPaginationTable from "../../components/Table/UBKPaginationTable";
import { Table, Pagination, Image, Modal } from "antd";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";
import { Drawer } from "@mui/material";
import SelectRequired from "../../components/UBKSelect/SelectRequired";

import {
  getNewData,
  addData,
  deleteData,
  updateData,
  getadmindataUrl,
  branchTableUrl as cachekey,
  getbranchdataUrl
} from "../../Api/api";

import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
import EditBranch from "./EditBranch ";
import UBKCheckbox from "../../components/UBKCheckbox";

// ==========MAIN FUNCTION ======================



function AddBranch() {
  const path = ConfigFile.BaseUrl;
  const [classnme, setclassnme] = useState("");
  const [open, setOpen] = useState(false);
  const [img, setImage] = useState();
  const [previewImage,setPreviewImage]=useState("");
  const [branchName,setBranchName]=useState("");
  const [id, selectedId] = useState();
  const [previewOpen,setPreviewOpen]=useState(false)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const addPageIndex = (page) => {
    setController({
      ...controller,
      page: page,
    });
  };

  const actionView = [
    {
      key: "Edit",
      status: true,
    },
    {
      key: "Delete",
      status: true,
    },
    {
      key: "View",
      status: false,
    },
  ];

  const addMutation = async (url, newData) => {
    try {
      await mutate(addData(url, newData), addOptions(newData));

      toast.success("Success! Added new item.", {
        duration: 1000,
        icon: "🎉",
      });
    } catch (err) {
      toast.error("Failed to add the new item.", {
        duration: 1000,
      });
    }
  };

  const {
    isLoading: menuLoading,
    error: menuError,
    data: permissionMenu
  } = useSWR("/admin/Admin/getadminMenu", getNewData);
  const {
    isLoading,
    error,
    data: newData,
    mutate,
  } = useSWR(`${cachekey}?page=${tableParams.pagination.current}&per_page=${tableParams.pagination.pageSize}`, getNewData);

  const {
    isLoading: loadingServices,
    error: serviceError,
    data: serviceList,
  } = useSWR("/admin/Admin/servicelist", getNewData);

  // const {
  //   isLoading:isLoadingAdminData,
  //   error,
  //   data: newdata,
  //   mutate,
  // } = useSWR("/api/superAdmin/admintable" + `?page=${controller.page + 1}&per_page=10`, getNewData);


  const deleteTodoMutation = async (id) => {
    const url = "/branchapi/frontoffice/deletevisitors";
    try {
      await mutate(deleteData(url, id), deleteTodoOptions(id));
      toast.success("Success! Deleted item.", {
        duration: 1000,
      });
    } catch (err) {
      toast.error("Failed to delete the item.", {
        duration: 1000,
      });
    }
  };

  const updateTodoMutation = async (url, upData) => {
    try {
      const res = await mutate(updateData(url, upData));
      console.log(res);
      if (Array.isArray(res?.data)) {
        globalMutate(`${getbranchdataUrl}?id=${id}`, res);
      }
      toast.success("Success! Updated item.", {
        duration: 1000,
        icon: "🚀",
      });
    } catch (err) {
      toast.error("Failed to update the item.", {
        duration: 1000,
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const url = "/admin/Admin/editbranch";
    updateTodoMutation(url, formData);
    e.target.reset();
    DrawerUpdate(false);
  };

  const handleSubmit = (event) => {
    console.log("2222222222", "handleSubmit");
    event.preventDefault();
    const formData = new FormData(event.target);
    addMutation("/admin/Admin/add_branch", formData);
    event.target.reset();
  };

  const DrawerUpdate = (status) => {
    console.log("dsdsdsdsd", status);
    setOpen(status);
  };
  const onChange = (page) => {
    console.log(page);
    setTableParams((prev) => (
      {
        ...prev,
        pagination: {
          current: page,
          pageSize: prev.pagination.pageSize,
        }
      }))
  };

  console.log("2222222222", "okkk");

  // const columns = useMemo(() => [
  //   {
  //     id: "1",
  //     name: "First Name",
  //     selector: "first_name",
  //     sortable: true,
  //   },
  //   {
  //     id: "2",
  //     name: "Last Name",
  //     selector: "last_name",
  //     sortable: true,
  //   },
  //   {
  //     id: "3",
  //     name: "Email",
  //     selector: "email_id",
  //     sortable: true,
  //   }
  // ]);

  const columns = [
    {
      title: 'Branch Name',
      dataIndex: 'branch_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      // width: '30%',
      key: 'email_id',
    },
    {
      title: 'Conatct Person',
      dataIndex: 'conatct_person',
    },
    {
      title: 'Conatct Number',
      dataIndex: 'conatct_number',
    },
    {
      title: 'Branch Logo',
      // dataIndex: 'branch_logo',
      render: (item, record, index) => <div style={{ display: "flex", justifyContent: "center" }}>
        <Image
          // width={200}
          style={{ height: "40px" }}
          src={`${ConfigFile.ImageUrl}/branch/logo/${record?.branch_logo}`}
          
        // placeholder={
        //   <Image
        //     // style={{height:"40px"}}
        //     preview={false}
        //     src={`${ConfigFile.ImageUrl}/branch/logo/${record?.branch_logo}`}
        //     width={200}
        //   />
        // }
        preview={{visible:false,onVisibleChange:()=>{setPreviewOpen(true);
          setPreviewImage(`${ConfigFile.ImageUrl}/branch/logo/${record?.branch_logo}`);
          setBranchName(record.branch_name);
        }}}
        />
      </div>
    },
    // Table.EXPAND_COLUMN,
    {
      title: "Status",
      // dataIndex:"status"
      render: (item, record, index) => (
        record.status === "Active" ?
          <MDBox color="success">Active</MDBox>
          :
          <MDBox color="error">Disabled</MDBox>
      )
    },
    {
      title: 'Action',
      // dataIndex: 'id',
      // width: '30%',
      // key: 'email_id',
      render: (item, record, index) => <div onClick={() => editClick(record.branch_id)} style={{ fontSize: "11px" }}>
        {/* {!record?.menu_name ? */}
        <IconButton>
          <BorderColorIcon color="success" fontSize="small" />
        </IconButton>
        {/* : null */}
        {/* } */}
      </div>,
    },

  ];
  const editClick = (id) => {
    selectedId(id);
    // setOpen(status);
    DrawerUpdate(true);
  };

  // const handleTableChange = (pagination, filters, sorter) => {
  //   setTableParams({
  //     pagination,
  //     filters,
  //     ...sorter,
  //   });
  // }

  const getCallbackSingle = (action) => (event) => {
    if (event == null || event == "undefined" || event == "") {
    } else {
      if (event[0].status === "done") {
        var name = event[0].response.data;
        setImage(name);
      } else {
      }
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Drawer open={open} anchor={"right"} onClose={() => DrawerUpdate(false)}>
        <EditBranch data={{ id, handleUpdate }} />
      </Drawer>
      <Modal open={previewOpen} title={branchName} footer={null} onCancel={()=>setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
        </Modal>
      <Toaster toastOptions={{ position: "top-center" }} />
      <MDBox pt={8} pb={1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Add Branch
                  </MDTypography>
                </MDBox>
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput required="required" name="school_name" type="text" label="Branch Name" />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput required="required" name="email" type="email" label="Email" />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput name="password" type="password" label="Password" required={"required"} />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="location"
                            type="text"
                            label="Location Name"
                            required={"required"}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="contactPersonName"
                            type="text"
                            label="Contact Person"
                            required={"required"}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <MDInput
                            name="contact"
                            type="tel"
                            label="Contact Number"
                            required={"required"}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDBox>
                        <MDBox>
                          <UBKfile
                            url={`${path}/superAdmin/upload_logo`}
                            numberImage={1}
                            oldImage=""
                            parentCallback={getCallbackSingle()}
                          />
                          <MDInput
                            className="noDisplay"
                            type="text"
                            name="file"
                            defaultValue={img ? img : ""}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MDBox>
                        <MDBox>
                          {/* <SelectRequired
                            label="Services*"
                            required={true}
                            name="services"
                            options={serviceList?.data}
                            placeholder="Select Service"
                          /> */}
                          <UBKCheckbox
                            label="Services"
                            options={Array.isArray(serviceList?.data) ? serviceList.data : []}
                            name="services[]"
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <UBKCheckbox
                        label="Permissions"
                        options={Array.isArray(permissionMenu) ? permissionMenu : []}
                        name="permission[]"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <MDBox>
                            <UBKSubmit type="submit" value="Save" color="primary" />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={1}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Branch List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {!isLoading && */}
                <>
                  <Table
                    style={{ margin: "50px" }}
                    columns={columns}
                    bordered={true}
                    // rowSelection={{
                    //   ...rowSelection,
                    //   checkStrictly,
                    // }}
                    rowKey={(record) => record.branch_id}
                    dataSource={newData?.data}
                    loading={isLoading}
                    pagination={{
                      current: tableParams.pagination.current,
                      pageSize: tableParams.pagination.pageSize,
                      total: newData?.pagination?.total_rows,
                      showSizeChanger: true,
                      onChange: onChange,
                      onShowSizeChange: (current, pageSize) => {
                        setTableParams((prev) => (
                          {
                            ...prev,
                            pagination: {
                              current: 1,
                              pageSize: pageSize,
                            }
                          }))
                      },
                      showTotal: (total) => `Total ${total} items`
                    }}
                    // columnTitle

                    expandable={{
                      expandedRowRender: (record) => (
                        <div
                          style={{
                            backgroundColor: "#eeeeee",
                          }}
                        >
                          <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Services</div>
                          <Grid container style={{ display: "flex", justifyContent: "center" }}>
                            {record?.services?.map((el, index) => (
                              <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                                <div style={{ textAlign: "center" }}>
                                  {el},
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                          <div style={{ color: "green", fontSize: "12px", fontWeight: "bold", textAlign: "center" }}>Permissions</div>
                          <Grid container style={{ display: "flex", justifyContent: "center" }}>
                            {record?.permission?.map((el, index) => (
                              <Grid item key={`rcrd${index}`} style={{ color: "#1A73E8", margin: "2px", padding: "2px", fontSize: "12px" }}>
                                <div style={{ textAlign: "center" }}>
                                  {el},
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      ),

                      // columnTitle: <div style={{ width: "200px", display: "flex", justifyContent: "center" }}>View Permission</div>,
                    }}
                  // onChange={handleTableChange}
                  />
                </>
                {/* } */}
                {/* <UBKPaginationTable
                  parentCallback={addPageIndex}
                  data={{
                    newData: newdata,
                    columns: columns,
                    action: true,
                    ActionView: actionView,
                    Loading: isLoading,
                    error: error,
                    editDesign: <EditAdmin data={{ handleUpdate }} />,
                    deleteTodoMutation: deleteTodoMutation,
                    open: open,
                    DrawerUpdate: DrawerUpdate,
                    viewDetails: "",
                  }}
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddBranch;
